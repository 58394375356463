var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _vm.isLoading ? _vm._l(3, function (i) {
    return _c('b-card', {
      key: i,
      staticClass: "mb-3"
    }, [_c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "85%"
      }
    }), _c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "55%"
      }
    }), _c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "70%"
      }
    }), _c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "80%"
      }
    }), _c('b-skeleton', {
      attrs: {
        "animation": "wave",
        "width": "70%"
      }
    })], 1);
  }) : _vm._e(), _vm.$options.components['advertisement-top-banner-text'] ? [_c('advertisement-top-banner-text')] : _vm._e(), _c('hr', {
    staticClass: "margintop-1"
  }), _c('b-row', [_c('b-col', {
    staticClass: "ml-5 mt-3",
    attrs: {
      "md": "7"
    }
  }, [_c('b-row', [_c('h5', [_vm._v("Request Medicine")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.currentQuote.office.officeName))])]), _c('b-col', [_c('b-button', {
    staticClass: "rounded-circle btn-edit"
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil-fill",
      "variant": "outline-primary"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "address"
  }, [_c('b-card-text', {
    staticClass: "mx-3"
  }, [_c('b-icon-geo-alt-fill', {
    attrs: {
      "variant": "success"
    }
  }), _vm._v(" " + _vm._s(_vm.currentQuote ? _vm.currentQuote.office && _vm.currentQuote.office.address && _vm.currentQuote.office.address.street1 : "") + " " + _vm._s(_vm.currentQuote ? _vm.currentQuote.office && _vm.currentQuote.office.address && _vm.currentQuote.office.address.city : "") + ", " + _vm._s(_vm.currentQuote ? _vm.currentQuote.office && _vm.currentQuote.office.address && _vm.currentQuote.office.address.state : "") + ", " + _vm._s(_vm.currentQuote ? _vm.currentQuote.office && _vm.currentQuote.office.address && _vm.currentQuote.office.address.country : "") + " ")], 1)], 1), _c('hr', {
    staticClass: "w-50"
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('h6', [_vm._v(" Deliver to " + _vm._s(_vm.userInfo.name.first) + " " + _vm._s(_vm.userInfo.name.last) + " ," + _vm._s(_vm.userInfo.addresses.zip) + " ")])]), _c('b-col', [_c('b-button', {
    staticClass: "rounded-circle btn-edit"
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil-fill",
      "variant": "outline-primary"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "address"
  }, [_c('b-card-text', {
    staticClass: "mx-3"
  }, [_c('b-icon-geo-alt-fill', {
    attrs: {
      "variant": "success"
    }
  }), _vm._v(" " + _vm._s(_vm.currentQuote ? _vm.currentQuote.office && _vm.currentQuote.office.address && _vm.currentQuote.office.address.street1 : "") + " " + _vm._s(_vm.currentQuote ? _vm.currentQuote.office && _vm.currentQuote.office.address && _vm.currentQuote.office.address.city : "") + ", " + _vm._s(_vm.currentQuote ? _vm.currentQuote.office && _vm.currentQuote.office.address && _vm.currentQuote.office.address.state : "") + ", " + _vm._s(_vm.currentQuote ? _vm.currentQuote.office && _vm.currentQuote.office.address && _vm.currentQuote.office.address.country : "") + " ")], 1)], 1), _c('hr', {
    staticClass: "w-50"
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('h6', [_vm._v("Delivery Date")])]), _c('b-col', [_c('b-button', {
    staticClass: "rounded-circle btn-edit"
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil-fill",
      "variant": "outline-primary"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "address"
  }, [_c('b-input', {
    staticClass: "w-50",
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1)], 1), _c('hr', {
    staticClass: "w-50"
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', [_vm._v("Choose Delivery Slots")]), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio-group', {
          attrs: {
            "id": "btn-radios-1",
            "options": _vm.options,
            "aria-describedby": ariaDescribedby,
            "name": "radios-btn-default",
            "button-variant": "outline-primary",
            "buttons": "",
            "stacked": ""
          },
          model: {
            value: _vm.selected,
            callback: function callback($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }])
  }), _c('b-row', {
    staticClass: "margin-top-70"
  }, [_c('b-button', {
    staticClass: "w-75 text-white",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.createQuote
    }
  }, [_vm._v(" Request Medicine ")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }